import React from 'react';
import Logo from '../assets/images/footerlogo.png';
import { Button, Form, Input } from 'antd';
import cogoToast from 'cogo-toast';
import { useMutation } from '@tanstack/react-query';
import { newsletter } from '../network/newsletter';

const Footer = () => {
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(newsletter, {
    onSuccess: () => {
      cogoToast.success("Message sent successfully", { position: "top-right" });
      form.resetFields();
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    mutate(values);
  };

  return (
    <footer id="footer">
      <div class="footer-newsletter">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <h4>Join Our Newsletter</h4>
              <p>Stay updated with the latest in technology and our offerings. Subscribe to our newsletter for insights and updates.</p>
              <Form
                form={form}
                name="newsletter"
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  required={false}
                  name="email"
                >
                  <Input type="email" className='input' />
                </Form.Item>
                <Button type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  style={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    border: 0,
                    fontSize: "16px",
                    padding: "22px 20px",
                    background: "#47b2e4",
                    color: "#fff",
                    transition: "0.3s",
                    position: "absolute",
                    borderRadius: "50px",
                    boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.1)"
                  }}
                >Subscribe
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-top">
        <div class="container">
          <div class="row">

            <div class="col-lg-3 col-md-6 footer-contact">
              <h3 >
                <img src={Logo} alt="Logo" style={{ width: "160px" }} />
              </h3>
              <p>
                Cairo <br />
                Egypt<br />
                <strong>Phone:</strong> +201004753538<br />
                <strong>Email:</strong> support@techiesonic.com<br />
              </p>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i class="bx bx-chevron-right"></i> <a href='/'  className="text-decoration-none" >Home</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href='/'  className="text-decoration-none" >About us</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href='/'  className="text-decoration-none" >Services</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href='/'  className="text-decoration-none" >Terms of service</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href='/'  className="text-decoration-none" >Privacy policy</a></li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><i class="bx bx-chevron-right"></i> <a href='/' className="text-decoration-none" >Web Design</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href='/' className="text-decoration-none" >Web Development</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href='/' className="text-decoration-none" >Product Management</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href='/' className="text-decoration-none" >Marketing</a></li>
                <li><i class="bx bx-chevron-right"></i> <a href='/' className="text-decoration-none" >Graphic Design</a></li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-6 footer-links">
              <h4>Our Social Networks</h4>
              <p>Follow us on social media for the latest updates and insights into our work and industry trends.</p>
              <div class="social-links mt-3">
                <a className="text-decoration-none" href="https://www.facebook.com/techieesonic" target="_blank" rel="noreferrer" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a className="text-decoration-none" href="https://www.instagram.com/techieesonic" target="_blank" rel="noreferrer" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a className="text-decoration-none" href="https://github.com/orgs/techiesonic" target="_blank" rel="noreferrer" class="twitter"><i class="bx bxl-github"></i></a>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="container footer-bottom clearfix">
        <div class="copyright">
          &copy; Copyright <strong><span>TechieSonic</span></strong>. All Rights Reserved
        </div>
      </div>
    </footer>
  );
};


export default Footer;
