import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import QueryProvider from './services/react-query';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import './assets/css/style.css';

function App() {
  return (
    <QueryProvider>
      <Navbar />
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <Hero />
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <About />
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <Services />
      </ScrollAnimation>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
        <Contact />
      </ScrollAnimation>
      <Footer />
    </QueryProvider>
  );
}

export default App;