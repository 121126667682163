import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import Logo from '../assets/images/logo.png';

const { Header } = Layout;

const Navbar = () => {
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const [currentSection, setCurrentSection] = useState('hero');

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY === 0;
      setScrolledToTop(isTop);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const items = [
    { key: 'hero', label: <a href="#hero" className={`nav-link scrollto ${currentSection === 'hero' ? 'active' : ''}`}>Home</a> },
    { key: 'about', label: <a href="#about" className={`nav-link scrollto ${currentSection === 'about' ? 'active' : ''}`}>About</a> },
    { key: 'services', label: <a href="#services" className={`nav-link scrollto ${currentSection === 'services' ? 'active' : ''}`}>Services</a> },
    { key: 'contact', label: <a href="#contact" className={`nav-link scrollto ${currentSection === 'contact' ? 'active' : ''}`}>Contact</a> },
  ];

  return (
    <Header
      style={{
        display: 'flex',
        padding: "15px",
        alignItems: 'center',
        backgroundColor: scrolledToTop ? '#37517e' : '#283a5ae6',
        transition: 'background-color 0.3s ease',
      }}
      id="header"
      className="fixed-top text-decoration-none"
    >
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto text-decoration-none">
          <img src={Logo} alt="Logo" />
        </h1>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[currentSection]} 
          items={items}
          style={{ flex: 1, minWidth: 0, alignItems: 'end', justifyContent: 'end' }}
          overflowedIndicator={<MenuOutlined />}
        />
      </div>
    </Header>
  );
};

export default Navbar;
