import React from 'react';

const About = () => (
  <section id="about" className="about">
    <div className="container">
      <div className="section-title">
        <h2>About Us</h2>
      </div>
      <div className="row content">
        <div className="col-lg-6">
          <p>Techie Sonic is a leading provider of technology solutions, specializing in web development, mobile app creation, and network solutions.</p>
          <ul>
            <li><i class="bi bi-check2-all"></i> Custom web and mobile application development</li>
            <li><i class="bi bi-check2-all"></i> Scalable and secure network solutions</li>
            <li><i class="bi bi-check2-all"></i>Expert IT consultancy and support</li>
          </ul>
        </div>
        <div className="col-lg-6 pt-4 pt-lg-0">
          <p>We are committed to delivering high-quality services tailored to meet the unique needs of our clients.</p>
          <a href='/' className="text-decoration-none btn-learn-more">Learn More</a>
        </div>
      </div>
    </div>
  </section>
);

export default About;
