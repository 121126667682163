import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Button, Form, Input } from "antd";
import cogoToast from "cogo-toast";
import { contactUs } from '../network/contact';

const Contact = () => {
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation(contactUs, {
    onSuccess: () => {
      cogoToast.success("Email sent successfully", { position: "top-right" });
      form.resetFields();
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    mutate(values);
  };

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
          <p>We'd love to hear from you! Whether you have a project in mind or need more information, reach out to us.</p>
        </div>
        <div className="row">
          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Cairo, Egypt</p>
              </div>
              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>support@techiesonic.com</p>
              </div>
              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+201004753538</p>
              </div>
              <iframe
                title="Cairo Downtown Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6904.267570015613!2d31.2357116!3d30.0444196!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145838f1c9e1b8db%3A0x5b4c8c8b26b1b5dd!2sCairo+Downtown!5e0!3m2!1sen!2seg!4v1539943755621"
                frameBorder="0"
                style={{
                  border: 0,
                  width: "100%",
                  height: "290px",
                }}
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <Form
              form={form}
              name="contact"
              onFinish={onFinish}
              layout="vertical"
              className="php-email-form"
            >
              <Form.Item
                name="name"
                label='Name'
                required={false}
                rules={[
                  {
                    required: true,
                    message: 'Please input your name!',
                    whitespace: true,
                  },
                ]}
              >
                <Input className='mb-2' />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone"
                required={false}
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                    whitespace: true,
                  },
                ]}
              >
                <Input className='mb-2' />
              </Form.Item>
              <Form.Item
                name="email"
                label='Email'
                required={false}
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input className='mb-2' />
              </Form.Item>
              <Form.Item
                name="message"
                label='Message'
                required={false}
                rules={[
                  {
                    required: true,
                    message: 'Please input your message!',
                  },
                ]}
              >
                <Input.TextArea showCount maxLength={200} style={{ height: 220, resize: 'none' }} />
              </Form.Item>
              <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit" className="w-full mt-3">
                  Send
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
